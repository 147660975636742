import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { STAGE_WIDTH, STAGE_HEIGHT } from '../constants';
import Stage from './Stage';
import { useGameStatus } from '../hooks/useGameStatus';
import { useGameLogic } from '../hooks/useGameLogic';
import Leaderboard from './Leaderboard';

const API_URL = 'https://tetris.pw/api';

const TetrisWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GameContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: stretch;
`;

const ScoreBoard = styled.div`
  width: 280px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 15px;

  h2 {
    margin: 0 0 15px 0;
    font-size: 24px;
    text-align: center;
  }
`;

const ScoreDisplay = styled.div`
  padding: 15px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  
  .score-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    
    .label {
      font-size: 16px;
      opacity: 0.9;
    }
    
    .value {
      font-size: 24px;
      font-weight: bold;
    }
  }
`;

const GameWrapper = styled.div`
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  &:focus {
    outline: none;
  }
`;

const InfoPanel = styled.div`
  width: 280px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const GameInfo = styled.div`
  padding: 15px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  h2 {
    margin: 0 0 15px 0;
    font-size: 24px;
    text-align: center;
  }

  p {
    font-size: 18px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:last-child {
      font-weight: bold;
      font-size: 20px;
    }
  }
`;

const GameOverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;

  h3 {
    margin: 0;
    font-size: 20px;
    text-align: center;
    color: #ff4444;
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  margin: 5px 0;
  background: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.2s;
  &:hover {
    background: #444;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Input = styled.input`
  padding: 12px 15px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.2;
  height: 44px;
  transition: all 0.2s;
  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.15);
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
`;

interface Score {
  _id: string;
  name: string;
  score: number;
  rows: number;
  date: string;
}

const Tetris: React.FC = () => {
  const [playerName, setPlayerName] = useState('');
  const [highScores, setHighScores] = useState<Score[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scoreSubmitted, setScoreSubmitted] = useState(false);
  const gameWrapperRef = useRef<HTMLDivElement>(null);
  const { score, rows, addScore, resetScore } = useGameStatus();
  const { 
    player,
    resetPlayer, 
    playerRotate, 
    stage, 
    setStage, 
    gameOver, 
    setGameOver,
    dropPlayer,
    movePlayer,
    drop
  } = useGameLogic(addScore);

  const fetchHighScores = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/scores`);
      if (!response.ok) throw new Error('Failed to fetch scores');
      const data = await response.json();
      setHighScores(data);
    } catch (error) {
      console.error('Error fetching scores:', error);
    }
  }, []);

  useEffect(() => {
    fetchHighScores();
  }, [fetchHighScores]);

  const submitScore = async () => {
    if (!playerName.trim() || isSubmitting || scoreSubmitted) return;
    
    setIsSubmitting(true);
    try {
      const response = await fetch(`${API_URL}/scores`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: playerName.trim(),
          score,
          rows
        }),
      });

      if (!response.ok) throw new Error('Failed to submit score');
      
      await fetchHighScores();
      setPlayerName('');
      setScoreSubmitted(true);
    } catch (error) {
      console.error('Error submitting score:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const start = useCallback(() => {
    setStage(createStage());
    setGameOver(false);
    resetScore();
    resetPlayer();
    setPlayerName('');
    setScoreSubmitted(false);
    setTimeout(() => {
      gameWrapperRef.current?.focus();
    }, 0);
  }, [resetPlayer, setStage, setGameOver, resetScore]);

  const createStage = () =>
    Array.from(Array(STAGE_HEIGHT), () =>
      new Array(STAGE_WIDTH).fill([0, 'clear'])
    );

  const keyUp = useCallback(({ keyCode }: { keyCode: number }) => {
    if (!gameOver) {
      if (keyCode === 37) { // Left arrow
        movePlayer(-1);
      } else if (keyCode === 39) { // Right arrow
        movePlayer(1);
      } else if (keyCode === 40) { // Down arrow
        drop();
      } else if (keyCode === 38) { // Up arrow
        playerRotate();
      } else if (keyCode === 32) { // Space
        dropPlayer();
      }
    }
  }, [gameOver, movePlayer, drop, playerRotate, stage, dropPlayer]);

  useEffect(() => {
    window.addEventListener('keyup', keyUp);
    return () => {
      window.removeEventListener('keyup', keyUp);
    };
  }, [keyUp]);

  return (
    <TetrisWrapper>
      <GameContainer>
        <InfoPanel>
          <Leaderboard scores={highScores} />
        </InfoPanel>

        <GameWrapper ref={gameWrapperRef} tabIndex={0}>
          <Stage stage={stage} />
        </GameWrapper>

        <InfoPanel>
          <GameInfo>
            <h2>Game Info</h2>
            <p>
              <span>Score:</span>
              <span>{score}</span>
            </p>
            <p>
              <span>Rows:</span>
              <span>{rows}</span>
            </p>
          </GameInfo>
          
          {!gameOver ? (
            <Button onClick={start}>New Game</Button>
          ) : (
            <GameOverContainer>
              <h3>Game Over!</h3>
              <Input
                type="text"
                placeholder="Enter your name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                maxLength={20}
                disabled={scoreSubmitted}
              />
              <Button 
                onClick={submitScore} 
                disabled={!playerName.trim() || isSubmitting || scoreSubmitted}
              >
                {isSubmitting ? 'Submitting...' : scoreSubmitted ? 'Score Submitted!' : 'Submit Score'}
              </Button>
              <Button onClick={start}>Play Again</Button>
            </GameOverContainer>
          )}
        </InfoPanel>
      </GameContainer>
    </TetrisWrapper>
  );
};

export default Tetris; 