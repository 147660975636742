import React from 'react';
import styled from 'styled-components';
import { TETROMINOS, CELL_SIZE } from '../constants';

type TetrominoType = keyof typeof TETROMINOS;

interface CellProps {
  type: TetrominoType;
}

interface StyledCellProps {
  type: TetrominoType;
  color: string;
}

const StyledCell = styled.div<StyledCellProps>`
  width: ${CELL_SIZE}px;
  height: ${CELL_SIZE}px;
  background: rgba(${props => props.color}, 0.8);
  border: ${props => (props.type === 0 ? '0px solid' : '4px solid')};
  border-bottom-color: rgba(${props => props.color}, 0.1);
  border-right-color: rgba(${props => props.color}, 1);
  border-top-color: rgba(${props => props.color}, 1);
  border-left-color: rgba(${props => props.color}, 0.3);
  box-sizing: border-box;
`;

const Cell: React.FC<CellProps> = ({ type }) => (
  <StyledCell
    type={type}
    color={TETROMINOS[type].color}
  />
);

export default React.memo(Cell); 