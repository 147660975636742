import React from 'react';
import styled from 'styled-components';
import Cell from './Cell';
import { TETROMINOS, CELL_SIZE } from '../constants';

type TetrominoType = keyof typeof TETROMINOS;
type STAGECELL = [TetrominoType | 0, string];
type STAGE = STAGECELL[][];

interface StageProps {
  stage: STAGE;
}

interface StageWrapperProps {
  width: number;
  height: number;
}

const StageWrapper = styled.div<StageWrapperProps>`
  display: grid;
  grid-template-rows: repeat(${props => props.height}, ${CELL_SIZE}px);
  grid-template-columns: repeat(${props => props.width}, ${CELL_SIZE}px);
  grid-gap: 1px;
  border: 2px solid #333;
  background: #111;
  padding: 2px;
`;

const Stage: React.FC<StageProps> = ({ stage }) => (
  <StageWrapper width={stage[0].length} height={stage.length}>
    {stage.map((row, y) => row.map((cell, x) => <Cell key={`${y}-${x}`} type={cell[0] as TetrominoType} />))}
  </StageWrapper>
);

export default Stage; 