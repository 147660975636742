import React from 'react';
import styled from 'styled-components';

const LeaderboardWrapper = styled.div`
  background: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 8px;
  color: white;
  flex-grow: 1;
  min-height: 200px;
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0 0 15px 0;
    font-size: 20px;
    text-align: center;
  }
`;

const ScoreList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
`;

const ScoreItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 14px;
  transition: background 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  .rank-name {
    display: flex;
    align-items: center;
    gap: 10px;
    
    .rank {
      min-width: 25px;
      text-align: right;
      opacity: 0.7;
    }
    
    .name {
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .score {
    font-weight: bold;
    font-size: 16px;
    min-width: 60px;
    text-align: right;
  }
`;

interface Score {
  _id: string;
  name: string;
  score: number;
  rows: number;
  date: string;
}

interface Props {
  scores: Score[];
}

const Leaderboard: React.FC<Props> = ({ scores }) => {
  return (
    <LeaderboardWrapper>
      <h3>Leaderboard</h3>
      <ScoreList>
        {scores.map((score, index) => (
          <ScoreItem key={score._id}>
            <div className="rank-name">
              <span className="rank">{index + 1}.</span>
              <span className="name">{score.name}</span>
            </div>
            <span className="score">{score.score}</span>
          </ScoreItem>
        ))}
      </ScoreList>
    </LeaderboardWrapper>
  );
};

export default Leaderboard; 