import { useState, useCallback } from 'react';
import { ROWPOINTS } from '../constants';

export const useGameStatus = () => {
  const [score, setScore] = useState(0);
  const [rows, setRows] = useState(0);

  const addScore = useCallback((rowsCleared: number) => {
    if (rowsCleared > 0) {
      // Calculate points based on the number of rows cleared
      const points = ROWPOINTS[rowsCleared as keyof typeof ROWPOINTS] || rowsCleared * 80;
      
      setScore(prev => prev + points);
      setRows(prev => prev + rowsCleared);
    }
  }, []);

  const resetScore = useCallback(() => {
    setScore(0);
    setRows(0);
  }, []);

  return { 
    score, 
    rows, 
    addScore, 
    resetScore 
  };
}; 