import { useState, useCallback, useEffect } from 'react';
import { TETROMINOS, STAGE_WIDTH, STAGE_HEIGHT, INITIAL_DIFFICULTY } from '../constants';

type TetrominoType = keyof typeof TETROMINOS;
type TetrominoShape = (string | number)[][];
type StageCell = [TetrominoType | 0, string];
type Stage = StageCell[][];

interface PlayerState {
  pos: { x: number; y: number };
  tetromino: TetrominoShape;
  collided: boolean;
}

const createStage = (): Stage =>
  Array.from(Array(STAGE_HEIGHT), () =>
    new Array(STAGE_WIDTH).fill([0, 'clear'] as StageCell)
  );

const randomTetromino = () => {
  const tetrominos = 'IJLOSTZ';
  const randTetromino = tetrominos[Math.floor(Math.random() * tetrominos.length)] as TetrominoType;
  return TETROMINOS[randTetromino];
};

export const useGameLogic = (addScore: (rowsCleared: number) => void) => {
  const [stage, setStage] = useState<Stage>(createStage());
  const [player, setPlayer] = useState<PlayerState>({
    pos: { x: STAGE_WIDTH / 2 - 2, y: 0 },
    tetromino: randomTetromino().shape,
    collided: false,
  });
  const [gameOver, setGameOver] = useState(false);
  const [dropTime, setDropTime] = useState(INITIAL_DIFFICULTY);

  const checkCollision = (
    playerToCheck: PlayerState,
    stage: Stage,
    moveX: number = 0,
    moveY: number = 0
  ): boolean => {
    for (let y = 0; y < playerToCheck.tetromino.length; y++) {
      for (let x = 0; x < playerToCheck.tetromino[y].length; x++) {
        if (playerToCheck.tetromino[y][x] !== 0) {
          const nextY = y + playerToCheck.pos.y + moveY;
          const nextX = x + playerToCheck.pos.x + moveX;

          if (
            nextY < 0 ||
            nextY >= STAGE_HEIGHT ||
            nextX < 0 ||
            nextX >= STAGE_WIDTH ||
            (stage[nextY] && stage[nextY][nextX][1] !== 'clear')
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const movePlayer = useCallback((dir: number) => {
    if (!gameOver && !checkCollision(player, stage, dir, 0)) {
      setPlayer(prev => ({
        ...prev,
        pos: { x: prev.pos.x + dir, y: prev.pos.y },
        collided: false
      }));
    }
  }, [gameOver, player, stage]);

  const rotate = (matrix: TetrominoShape): TetrominoShape => {
    const rotated = matrix.map((_, i) => matrix.map(col => col[i]));
    return rotated.map(row => [...row].reverse());
  };

  const playerRotate = useCallback(() => {
    if (gameOver) return;

    const clonedPlayer = {
      ...player,
      tetromino: rotate(player.tetromino),
      collided: false
    };

    let offset = 1;
    while (checkCollision(clonedPlayer, stage, 0, 0)) {
      clonedPlayer.pos.x += offset;
      offset = -(offset + (offset > 0 ? 1 : -1));
      if (offset > clonedPlayer.tetromino[0].length) {
        return;
      }
    }

    setPlayer(clonedPlayer);
  }, [gameOver, player, stage]);

  const resetPlayer = useCallback(() => {
    setPlayer({
      pos: { x: STAGE_WIDTH / 2 - 2, y: 0 },
      tetromino: randomTetromino().shape,
      collided: false,
    });
    setGameOver(false);
  }, []);

  const clearRows = (newStage: Stage): { clearedStage: Stage; rowsCleared: number } => {
    let rowsCleared = 0;
    const clearedStage = newStage.reduce((acc, row) => {
      if (row.every(cell => cell[0] !== 0)) {
        rowsCleared += 1;
        acc.unshift(new Array(STAGE_WIDTH).fill([0, 'clear'] as StageCell));
      } else {
        acc.push(row);
      }
      return acc;
    }, [] as Stage);

    // Only count each row once
    return { clearedStage, rowsCleared: Math.min(rowsCleared, 4) };
  };

  const updateStage = useCallback((prevStage: Stage): Stage => {
    // First flush the stage
    const newStage = prevStage.map(row =>
      row.map(cell => (cell[1] === 'clear' ? [0, 'clear'] as StageCell : cell))
    );

    // Then draw the tetromino
    player.tetromino.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value !== 0) {
          const newY = y + player.pos.y;
          const newX = x + player.pos.x;
          if (newY >= 0 && newY < STAGE_HEIGHT && newX >= 0 && newX < STAGE_WIDTH) {
            newStage[newY][newX] = [value as TetrominoType, player.collided ? 'merged' : 'clear'];
          }
        }
      });
    });

    // Then check if we collided
    if (player.collided) {
      const { clearedStage, rowsCleared } = clearRows(newStage);
      if (rowsCleared > 0) {
        addScore(rowsCleared);
      }
      resetPlayer();
      return clearedStage;
    }

    return newStage;
  }, [player, resetPlayer, addScore]);

  const drop = useCallback(() => {
    if (gameOver) return;

    if (!checkCollision(player, stage, 0, 1)) {
      setPlayer(prev => ({
        ...prev,
        pos: { x: prev.pos.x, y: prev.pos.y + 1 },
        collided: false
      }));
    } else {
      // Game Over
      if (player.pos.y < 1) {
        setGameOver(true);
        return;
      }
      // Collision occurred, set collided to true
      setPlayer(prev => ({
        ...prev,
        collided: true
      }));
    }
  }, [gameOver, player, stage]);

  const dropPlayer = useCallback(() => {
    if (gameOver) return;

    // Clone the player to avoid direct state mutation
    const clonedPlayer = {
      ...player,
      pos: { ...player.pos }
    };

    let newY = clonedPlayer.pos.y;
    
    // Find the lowest possible position
    while (!checkCollision({ ...clonedPlayer, pos: { ...clonedPlayer.pos, y: newY + 1 } }, stage, 0, 0)) {
      newY += 1;
    }

    // Always update the player position and set collided
    setPlayer(prev => ({
      ...prev,
      pos: { ...prev.pos, y: newY },
      collided: true
    }));
  }, [gameOver, player, stage]);

  // Start the game
  useEffect(() => {
    let dropInterval: NodeJS.Timeout;

    if (!gameOver) {
      dropInterval = setInterval(() => {
        drop();
      }, dropTime);
    }

    return () => {
      if (dropInterval) {
        clearInterval(dropInterval);
      }
    };
  }, [drop, dropTime, gameOver]);

  // Update the stage
  useEffect(() => {
    if (!gameOver) {
      setStage(prev => updateStage(prev));
    }
  }, [updateStage, gameOver, player.collided, player.pos.x, player.pos.y]);

  // Reset game when component mounts
  useEffect(() => {
    resetPlayer();
    setStage(createStage());
  }, [resetPlayer]);

  return {
    stage,
    setStage,
    player,
    gameOver,
    setGameOver,
    dropPlayer,
    movePlayer,
    playerRotate,
    drop,
    resetPlayer
  };
}; 